
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Form, Field, ErrorMessage } from "vee-validate";
import TotalPremiumWidget from "@/components/packages/TotalPremiumWidget.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import * as Yup from "yup";
import { useRouter } from "vue-router";
import GoBackButton from "@/components/reusable/GoBackButton.vue";

export default defineComponent({
  name: "edit-personal-accident-house-insurance-addition",
  components: { Form, Field, ErrorMessage, TotalPremiumWidget, GoBackButton },
  data() {
    return {
      displayBreakdown: false,
      displayForm: true,
      displayError: false,
      policySummary: {},
      benefitLimit: "",
      includeMedicalExpenses: false,
      isChecked: false,
      rate: 0.001,
      ratePublicId: "",
      medicalRate: 0.15,
      medicalRatePublicId: "",
      totalPremium: 0.0 as number,
      medicalExpensesValue: 0.0 as number,
      publicId: this.$route.params.publicId as string,
      housePolicyId: "",
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const personalAccientValidator = Yup.object().shape({
      benefitLimit: Yup.string()
        .required()
        .label("Benefit limit"),
    });

    const updateCartInvoice = (values, { resetForm }) => {
      // console.log(values);
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Submit data
          if (JwtService.getToken()) {
            const formData = new FormData();
            formData.append("benefitLimit", values["benefitLimit"]);
            formData.append("includeMedicalExpenses", values["isChecked"]);
            formData.append("totalPremium", values["totalPremium"]);
            formData.append("businessClass", "HOM");
            formData.append("businessType", "HOU");
            formData.append("package", "HPA");
            // return new Response(formData).text().then(console.log);

            ApiService.setHeader();
            ApiService.post(
              `${variables.CART_ROUTE}/${values["publicId"]}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                resetForm();
                // Re-route to policy certificate upload page
                router.push({
                  name: "insurance-packages-policy-period",
                  params: {
                    publicId: response.data.data.cartItemPublicId,
                  },
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Edit", [
        "Householders Insurance",
        "Personal Accident",
        "Renewal",
      ]);
    });

    return { submitButton1, personalAccientValidator, updateCartInvoice };
  },
  created() {
    // Get invoice information
    this.getInvoiceInformation();

    // Get the rates for personal accident package
    this.getRates();

    //Set page title
    document.title =
      "Renewal: Edit Personal Accident Householders Insurance | " +
      process.env.VUE_APP_TITLE;
  },
  methods: {
    getInvoiceInformation: function() {
      const router = useRouter();

      //Get cart item details
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.CART_ROUTE}/${this.publicId}`)
          .then(({ data }) => {
            //Assign data to corresponding fields
            if (data.data != "") {
              this.policySummary = data.data;
              this.totalPremium = data.data.premium;
              this.housePolicyId = data.data.policyPublicId;
              this.benefitLimit = data.data.benefitLimit;
              this.includeMedicalExpenses = data.data.includeMedicalExpenses;
              this.isChecked = data.data.includeMedicalExpenses;
            } else {
              this.displayError = true;
            }
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
            router.push({ name: "insurance-packages-cart" });
          });
      }
    },
    getRates() {
      //Fetch list of underwriters for house building insurance package
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.HOUSE_PERSONAL_ACCIDENT_RATE_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.rate = data.data.accidentalDeath.value;
            this.ratePublicId = data.data.accidentalDeath.id;
            this.medicalRate = data.data.reasonableMedicalExpenses.value;
            this.medicalRatePublicId = data.data.reasonableMedicalExpenses.id;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    premium(event) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      setTimeout(() => {
        this.$nextTick(() => (event.target.value = newValue));
      }, 0.1);

      // Remove comma from vehicle value
      const value = variables.removeComma(newValue);
      // const value = variables.removeComma(event.target.value);

      // Check if value is a number
      if (isNaN(value) == false) {
        // Calculate premium from vehicle value
        variables.premiumCalculator(
          event.target.ariaValueNow,
          value,
          event.target.ariaValueText
        );
      } else {
        return;
      }

      // Append total premium value to the total premium field
      this.totalPremium = variables.totalPremiumValue();
      if (this.includeMedicalExpenses || this.isChecked) {
        this.isChecked = this.includeMedicalExpenses = !this
          .includeMedicalExpenses;
        this.medicalExpenses();
      }
    },
    addCommaToNumber(event) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));
    },
    medicalExpenses() {
      this.isChecked = this.includeMedicalExpenses = !this
        .includeMedicalExpenses;

      const paValue = document.getElementById(
        "personal-accident-value"
      ) as HTMLElement;

      const medicalExpensesPremiumText = document.getElementById(
        "medical-expenses-premium"
      ) as HTMLElement;

      const totalPremiumText = document.getElementById(
        "total-premium"
      ) as HTMLElement;

      if (this.includeMedicalExpenses) {
        const premiumValue = variables.removeComma(paValue.textContent);
        const value = this.medicalRate * premiumValue;
        const newPremium = variables.totalPremiumValue() + value;

        if (isNaN(newPremium) == false) {
          medicalExpensesPremiumText.textContent = this.addCommaToNumberString(
            value
          );
          this.totalPremium = newPremium;
          totalPremiumText.textContent = this.addCommaToNumberString(
            newPremium
          );
        }
      } else {
        this.totalPremium = variables.totalPremiumValue();
        medicalExpensesPremiumText.textContent = "0";
      }
    },
    medicalExpensesRate(value) {
      const payload = {
        value: value,
        rate: this.medicalRatePublicId,
      } as object;

      if (JwtService.getToken()) {
        ApiService.setHeader();
        return ApiService.post(
          `${variables.CART_ADDITION_ROUTE}${this.housePolicyId}/premium`,
          payload
        )
          .then(({ data }) => {
            this.medicalExpensesValue = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
  },
});
